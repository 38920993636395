import Api from './api.service';

export default {
  store: (data) => Api.post('discounts', data),
  get: (params = {}) =>
    Api.get('discounts', {
      params,
    }),
  getId: (id, params = {}) =>
    Api.get(`discounts/${id}`, {
      params,
    }),
  update: (id, data) => Api.post(`discounts/${id}`, data),
  destroy: (id) => Api.delete(`discounts/${id}`),
};
